import { Packer } from 'docx'
import generatePdfFromTemplate from '~/pdfs/generatePdfFromTemplate'
import type { ExportDataColumn, ExportParams, PdfTemplate } from '~/pdfs/helpers'

export default async (columns?: ExportDataColumn[], data?: any[], params?: ExportParams, template?: PdfTemplate) => {
  const doc = await generatePdfFromTemplate(columns, data, params, template)
  const documentBlob = await Packer.toBlob(doc)
  const formData = new FormData()
  formData.append('document', documentBlob)

  const response = await $fetch('/api/file/convertToPDF', {
    method: 'POST',
    responseType: 'blob',
    body: formData,
  })
  if (response instanceof Blob) {
    downloadBlob(response, '.pdf', params?.dataEntity)
  }
}
