<script setup lang="ts">
import ComputerDocumentIcon from '~/assets/icons/topics/computerDocument.svg'
import type { ExportFormats } from '~/pdfs/helpers'

const props = withDefaults(defineProps<{
  isLoading?: boolean
  exportTypes?: ExportFormats[]
  button?: {
    secondary?: boolean
    class?: string
  }
}>(), { button: () => ({ secondary: true, class: 'md:min-h-10' }) })
const emit = defineEmits<{ (e: 'export', key: ExportFormats): void }>()

const { $i18n } = useNuxtApp()
const exportOptions = [
  { label: $i18n.t('button.export.CSV'), key: 'csv' },
  { label: $i18n.t('button.export.PDF'), key: 'pdf' },
]

const filteredExportOptions = computed(() => {
  if (!props.exportTypes) {
    return exportOptions
  }
  return exportOptions.filter(option => props.exportTypes?.includes(option.key as ExportFormats))
})

const handleSelect = (key: ExportFormats) => {
  emit('export', key)
}
</script>

<template>
  <n-dropdown
    placement="bottom-start"
    trigger="click"
    :options="filteredExportOptions"
    :disabled="isLoading"
    @select="handleSelect"
  >
    <n-button :secondary="button.secondary" :class="button.class" :disabled="isLoading" :loading="isLoading">
      <template #icon>
        <ComputerDocumentIcon />
      </template>
      {{ $t('button.export') }}
    </n-button>
  </n-dropdown>
</template>
