import { z } from 'zod'
import type { DataColumnFormatter, ExportData } from '~/pdfs/helpers'

export default () => {
  const { $i18n } = useNuxtApp()

  /* Formatters */
  const date = (format: 'numeric' | 'short' | 'dateTime' = 'numeric'): DataColumnFormatter => (v: any) => $i18n.d(z.coerce.date().parse(v), format)
  const translation = (prefix: string): DataColumnFormatter => (v: any) => $i18n.t(`${prefix}.${v}`)
  const array = (pointer?: string, separator = ', ', translationPrefix?: string): DataColumnFormatter => (v: any) => {
    const arr = pointer ? v.map((v: any) => v[pointer]) : v
    return arr.map((value: any) => translationPrefix ? $i18n.t(`${translationPrefix}.${value}`) : value).join(separator)
  }
  const extract = (segments: Array<string | string[]>, separator = ', '): DataColumnFormatter => (v: any) => segments.map((segment: string | string[]) => {
    if (Array.isArray(segment)) {
      return segment.map((s: string) => v[s]).join(' ')
    }
    return v[segment]
  }).join(separator)
  const number = (decimals: number = 2): DataColumnFormatter => (v: any) => z.coerce.number().parse(v).toFixed(decimals).toString()

  const isExporting = ref(false)
  async function exportData(exportData: ExportData) {
    const { columns, type, params, template, data } = exportData
    const columnsWithoutHidden = columns?.filter(c => !c.isHidden)
    isExporting.value = true
    if (type === 'csv' && columnsWithoutHidden) {
      useExportAsCSV(columnsWithoutHidden, data, params)
    } else if (type === 'pdf') {
      await useExportAsPDF(columnsWithoutHidden, data, params, template)
    }
    isExporting.value = false
  }

  return {
    isExporting,
    exportData,
    formatters: { date, translation, array, extract, number },
  }
}
