import type { Input, Options } from 'csv-stringify'
import { stringify } from 'csv-stringify/browser/esm/sync'

export default function stringifyDataToCSV(data: Input, options: Options): string {
  return stringify(data, {
    bom: !options.encoding,
    header: false,
    delimiter: ';',
    encoding: options.encoding ?? 'utf-8',
    ...options,
  })
}
