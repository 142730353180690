import type { DataColumnFormatter, ExportDataColumn } from '~/pdfs/helpers'

const defaultFormatter: DataColumnFormatter = (v: any) => {
  if (Array.isArray(v)) {
    return v.join(', ')
  }
  return v
}

export default (column: ExportDataColumn, dataValue: any, data: any) => {
  if (!dataValue && !column.render) {
    return ''
  }
  let renderedValue
  if (column.render) {
    renderedValue = column.render(data) ?? ''
  }

  const formatter = column.formatter ?? defaultFormatter
  return formatter(renderedValue ?? dataValue, data)
}
