import { Document, Footer, Header, type ISectionOptions, ImageRun, NumberFormat, PageBreak, PageNumber, Paragraph, Table, TableCell, TableRow, TextRun, WidthType } from 'docx'
import { get } from 'jsonpointer'

export type DataColumnFormatter = (arg1: any, arg2: any) => string
export type ExportDataColumn = {
  pointer: string
  title: string
  width?: number
  isHidden?: boolean
  formatter?: DataColumnFormatter
  render?: (arg: any) => string
}
export type ExportFormats = 'csv' | 'pdf'
export type ExportParams = {
  filtersDescription?: string
  dataEntity?: string
  headerTextSize?: number
  cellTextSize?: number
  csvEncoding?: BufferEncoding
  template?: {
    personId?: string
  }
  cellMargins?: {
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
}
export type PdfTemplate = 'default' | 'personDetails'
export type ExportData =
  | {
    type: 'csv'
    columns: ExportDataColumn[]
    data: any[]
    params?: ExportParams
    template?: undefined
  }
  | {
    type: 'pdf'
    columns?: ExportDataColumn[]
    data?: any[]
    params?: ExportParams
    template?: PdfTemplate
  }
const PDF_OPTIONS = {
  font: 'Arial',
  margins: {
    header: '0.7cm',
    right: '0.6cm',
    left: '0.6cm',
  },
} as const

function getDocxColumnWidth(column: ExportDataColumn) {
  if (column.width) {
    const sizeInDXA = column.width * 20
    return { size: sizeInDXA, type: WidthType.DXA }
  }
  return { type: WidthType.AUTO }
}
export function makePageBreak() {
  return new Paragraph({
    children: [new PageBreak()],
  })
}
export function makeDocxTableRow(columns: ExportDataColumn[], data: any, textSize?: number) {
  const rowColumns = columns.map((col) => {
    const dataValue = get(data, col.pointer)
    return new TableCell({
      ...getDocxColumnWidth(col),
      margins: { top: 16, bottom: 16, left: 16, right: 16 },
      children: [new Paragraph({
        children: [new TextRun({ text: useExportFormatDataValue(col, dataValue, data), font: PDF_OPTIONS.font, size: textSize })],
      })],
    })
  })
  return new TableRow({ children: rowColumns, cantSplit: true })
}

export async function makePDFHeader() {
  const HeaderImage = await (await fetch('/pdf/header.png')).arrayBuffer()

  return new Header({
    children: [
      new Paragraph({
        children: [
          new ImageRun({
            data: HeaderImage,
            transformation: { width: 760, height: 40 },
          }),
        ],
      }),
    ],
  })
}

export function makeDocxTableHeader(columns: ExportDataColumn[], params?: ExportParams) {
  return columns.map((col) => {
    return new TableCell({
      ...getDocxColumnWidth(col),
      margins: { top: 16, bottom: 16, left: 16, right: 16 },
      children: [new Paragraph({
        children: [new TextRun({ text: col.title, font: PDF_OPTIONS.font, bold: true, size: params?.headerTextSize })],
      })],
    })
  })
}

export function makePDFHeading(params?: ExportParams) {
  const { $i18n } = useNuxtApp()
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: $i18n.t('pdf.export.title', { date: $i18n.d(new Date(), 'numeric') }),
          bold: true,
          size: 32,
          font: PDF_OPTIONS.font,
        }),
        ...(params?.dataEntity
          ? [new TextRun({
              text: `: ${params.dataEntity}`,
              bold: true,
              size: 32,
              font: PDF_OPTIONS.font,
            })]
          : []),
      ],
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: $i18n.t('pdf.export.by', { email: useAuth().data.value?.user.email }),
          size: 25,
          font: PDF_OPTIONS.font,
        }),
      ],
    }),
  ]
}
export function makeFiltersDescriptions(params?: ExportParams) {
  const { $i18n } = useNuxtApp()
  return new Paragraph({
    spacing: { before: 350, after: 300 },
    border: { top: { style: 'single', size: 2, space: 15 } },
    children: [
      new TextRun({
        text: params?.filtersDescription ?? $i18n.t('filters.empty'),
        font: PDF_OPTIONS.font,
      }),
    ],
  })
}

export function makeDocxSubHeader(title: string) {
  return new Paragraph({
    children: [
      new TextRun({
        text: title,
        bold: true,
        size: 28,
        underline: {},
        font: PDF_OPTIONS.font,
      }),
    ],
    spacing: {
      after: 200,
      before: 200,
    },
    border: {
      top: {
        style: 'single',
        size: 4,
        space: 15,
        color: '000000',
      },
    },
  })
}

function mapTableDataToColumns(data: any[], columns: ExportDataColumn[]): any[] {
  return data.map(row =>
    columns.map((column) => {
      const value = get(row, column.pointer)
      return useExportFormatDataValue(column, value, data)
    }),
  )
}

function generateTableNoValuesText() {
  const { $i18n } = useNuxtApp()
  return new Paragraph({
    alignment: 'center',
    spacing: { before: 400 },
    children: [new TextRun({
      text: $i18n.t('notifications.error.noDataToExport'),
      font: PDF_OPTIONS.font,
      size: 25,
      bold: true,
    })],
  })
}

export function makeDocxTable(data: any[], columns: ExportDataColumn[], params?: ExportParams, rowBasedLayout: boolean = false) {
  if (data.length === 0) {
    return generateTableNoValuesText()
  }
  if (rowBasedLayout) {
    const mappedData = mapTableDataToColumns(data, columns)
    return new Table({
      rows: mappedData.flatMap(row =>
        columns.map((column, colIndex) => new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph({
                children: [new TextRun({ text: column.title, font: PDF_OPTIONS.font, bold: true, size: params?.headerTextSize })],
              })],
              margins: params?.cellMargins,
              ...getDocxColumnWidth(column),
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: row[colIndex] || '', size: params?.cellTextSize })] })],
              margins: params?.cellMargins,
            }),
          ],
        })),
      ),
      width: { size: '100%', type: 'pct' },
    })
  } else {
    return new Table({
      rows: [
        new TableRow({ children: makeDocxTableHeader(columns, params), tableHeader: true, cantSplit: true }),
        ...data.map(d => makeDocxTableRow(columns, d, params?.cellTextSize)),
      ],
      width: { size: '100%', type: 'pct' },
    })
  }
}

export async function createPDFDocument(sections: ISectionOptions['children']) {
  const { $i18n } = useNuxtApp()

  return new Document({
    creator: $i18n.t('app.title'),
    sections: [
      {
        properties: {
          page: { margin: PDF_OPTIONS.margins, pageNumbers: { start: 1, formatType: NumberFormat.DECIMAL } },
        },
        headers: { default: await makePDFHeader() },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                alignment: 'right',
                children: [
                  new TextRun({
                    font: PDF_OPTIONS.font,
                    children: [
                      $i18n.t('pdf.footer.pagination.start'),
                      PageNumber.CURRENT,
                      $i18n.t('pdf.footer.pagination.end'),
                      PageNumber.TOTAL_PAGES,
                    ],
                  }),
                ],
              }),
              new Paragraph({
                alignment: 'right',
                children: [
                  new TextRun({
                    font: PDF_OPTIONS.font,
                    text: $i18n.d(new Date(), 'dateTime'),
                  }),
                ],
              }),
            ],
          }),
        },
        children: sections,
      },
    ],
  })
}
