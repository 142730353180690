import { get } from 'jsonpointer'
import type { ExportDataColumn, ExportParams } from '~/pdfs/helpers'

export default (columns: ExportDataColumn[], data: any[], params?: ExportParams) => {
  const mappedColumnTitles = columns.reduce((items, col) => Object.assign(items, {
    [col.pointer]: col.title,
  }), {})

  const mappedData = data.map((rowData) => {
    return columns.reduce((items, col) => {
      const dataValue = get(rowData, col.pointer)
      return Object.assign(items, {
        [col.pointer]: useExportFormatDataValue(col, dataValue, rowData),
      })
    }, {})
  })

  const csvString = stringifyDataToCSV([mappedColumnTitles, ...mappedData], { encoding: params?.csvEncoding })
  const csvAsBlob = new Blob([csvString], { type: 'text/csv;charset=utf-8' })
  downloadBlob(csvAsBlob, '.csv', params?.dataEntity)
}
