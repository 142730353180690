import type { ExportDataColumn, ExportParams, PdfTemplate } from './helpers'
import generateDefaultPdfSections from '~/pdfs/templates/generateDefaultPdfSections'
import generatePersonDetailsPdfSections from '~/pdfs/templates/generatePersonDetailsPdfSections'
import { createPDFDocument } from '~/pdfs/helpers'

export default async (columns?: ExportDataColumn[], data?: any[], params?: ExportParams, template?: PdfTemplate) => {
  if (template === 'personDetails' && params?.template?.personId) {
    const personDetails = await generatePersonDetailsPdfSections(params)
    return createPDFDocument(personDetails)
  } else {
    return createPDFDocument(generateDefaultPdfSections(columns, data, params))
  }
}
