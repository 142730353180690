import { type ExportDataColumn, type ExportParams, makeDocxSubHeader, makeDocxTable, makePDFHeading, makePageBreak } from '~/pdfs/helpers'

export default async function (params: ExportParams) {
  const { $i18n, $trpc } = useNuxtApp()
  const { formatters } = useExport()
  const personId = params.template?.personId

  const personData = await $trpc.persons.findOnePerson.query({ id: personId })
  const casesData = await $trpc.exports.exportCasesTable.query({ where: { patientId: personId } })
  const immunizationsData = await $trpc.exports.exportImmunizationsTable.query({ where: { personId } })
  const { data: institutionsData } = await $trpc.institutions.findManyInstitution.query({ where: { members: { some: { personId } } } })
  const { data: hospitalizationsData } = await $trpc.hospitalizations.findManyHospitalization.query({ where: { personId } })

  const personColumns: ExportDataColumn[] = [
    { pointer: '/account/email', title: $i18n.t('account.email.label') },
    { pointer: '/firstName', title: $i18n.t('account.firstName.label') },
    { pointer: '/lastName', title: $i18n.t('account.lastName.label') },
    { pointer: '/birthDate', title: $i18n.t('person.birthDate.label'), formatter: formatters.date('numeric') },
    { pointer: '/gender', title: $i18n.t('person.gender.label'), formatter: formatters.translation('person.gender') },
    { pointer: '/account/mobilePhone', title: $i18n.t('person.mobilePhone.label') },
    { pointer: '/account/landLinePhone', title: $i18n.t('person.landLinePhone.label') },
    { pointer: '/isPregnant', title: $i18n.t('person.isPregnant.label'), formatter: formatters.translation('person.isPregnant') },
    { pointer: '/isSmoker', title: $i18n.t('person.isSmoker.label'), formatter: formatters.translation('person.isSmoker') },
    { pointer: '/weightInKg', title: $i18n.t('person.weight.label'), formatter: formatters.number() },
    { pointer: '/heightInCm', title: $i18n.t('person.height.label'), formatter: formatters.number() },
    { pointer: '/bmi', title: $i18n.t('person.bmi.label'), formatter: formatters.number() },
    { pointer: '/relevantPredispositions', title: $i18n.t('person.relevantPredispositions.label'), formatter: formatters.array('name') },
    { pointer: '/address/street', title: $i18n.t('person.address.street.label') },
    { pointer: '/address/postcode', title: $i18n.t('person.address.postcode.label') },
    { pointer: '/address/city', title: $i18n.t('person.address.city.label') },
    { pointer: '/livingSituation', title: $i18n.t('person.livingSituation.label') },
  ]
  const casesColumns: ExportDataColumn[] = [
    { pointer: '/referenceId', title: $i18n.t('case.referenceId.label') },
    { pointer: '/type', title: $i18n.t('case.type.label'), formatter: formatters.translation('disease.availableCaseTypes') },
    { pointer: '/disease/name', title: $i18n.t('case.diseaseId.label') },
    { pointer: '/state', title: $i18n.t('case.state.label'), formatter: formatters.translation('case.state') },
    { pointer: '/riskAssessment', title: $i18n.t('case.riskAssessment.label'), formatter: formatters.translation('case.riskAssessment.status') },
    { pointer: '/quarantineStart', title: $i18n.t('case.quarantineStart.label'), formatter: formatters.date('numeric') },
    { pointer: '/quarantineEnd', title: $i18n.t('case.quarantineEnd.label'), formatter: formatters.date('numeric') },
    { pointer: '/comment', title: $i18n.t('case.comment.label') },
    { pointer: '/houseHoldMembersSymptoms', title: $i18n.t('case.houseHoldMembersSymptoms.label') },
  ]
  const immunizationsColumns: ExportDataColumn[] = [
    { pointer: '/disease/name', title: $i18n.t('immunization.disease.label') },
    { pointer: '/type', title: $i18n.t('immunization.type.label'), formatter: formatters.translation('immunization.type') },
    { pointer: '/state', title: $i18n.t('immunization.state.label'), formatter: formatters.translation('immunization.state') },
    { pointer: '/startDate', title: $i18n.t('immunization.startDate.label'), formatter: formatters.date('numeric') },
    { pointer: '/endDate', title: $i18n.t('immunization.endDate.label'), formatter: formatters.date('numeric') },
    { pointer: '/validStart', title: $i18n.t('immunization.validStart.label'), formatter: formatters.date('numeric') },
    { pointer: '/validEnd', title: $i18n.t('immunization.validEnd.label'), formatter: formatters.date('numeric') },
  ]
  const institutionsColumns: ExportDataColumn[] = [
    { pointer: '/referenceId', title: $i18n.t('institution.referenceId.label') },
    { pointer: '/type/name', title: $i18n.t('institution.type.label') },
    { pointer: '/name', title: $i18n.t('institution.name.label') },
    { pointer: '/address/street', title: $i18n.t('person.address.street.label') },
    { pointer: '/address/city', title: $i18n.t('person.address.city.label') },
    { pointer: '/address/postcode', title: $i18n.t('person.address.postcode.label') },
    { pointer: '/account/email', title: $i18n.t('institution.email.label') },
  ]
  const hospitalizationsColumns: ExportDataColumn[] = [
    { pointer: '/institution/name', title: $i18n.t('hospitalization.institution.label') },
    { pointer: '/case', title: $i18n.t('hospitalization.case.label'), render: ({ case: caseData }) => {
      if (caseData) {
        return $i18n.t(`hospitalization.case.name`, {
          type: $i18n.t(`disease.availableCaseTypes.${caseData.type}`),
          disease: caseData.disease.name,
          date: $i18n.d(caseData.createdAt, 'numeric'),
        })
      } else {
        return $i18n.t('hospitalization.case.notAvailable')
      }
    } },
    { pointer: '/station', title: $i18n.t('hospitalization.station.label') },
    { pointer: '/startDate', title: $i18n.t('hospitalization.startDate.label'), formatter: formatters.date('numeric') },
    { pointer: '/endDate', title: $i18n.t('hospitalization.endDate.label'), formatter: formatters.date('numeric') },
    {
      pointer: '/hasIntensiveCare',
      title: $i18n.t('hospitalization.intensiveCare.label'),
      render: ({ hasIntensiveCare }) => $i18n.t(`hospitalization.intensiveCare.${hasIntensiveCare}`),
    },
  ]
  return [
    ...makePDFHeading(),
    makeDocxSubHeader($i18n.t('person.pdf.personalInfo.title')),
    makeDocxTable([personData], personColumns, { ...params, cellMargins: { top: 50, bottom: 50, left: 50, right: 50 } }, true),
    makePageBreak(),
    makeDocxSubHeader($i18n.t('person.pdf.casesInfo.title')),
    makeDocxTable(casesData, casesColumns, params),
    makePageBreak(),
    makeDocxSubHeader($i18n.t('person.pdf.immunizationsInfo.title')),
    makeDocxTable(immunizationsData, immunizationsColumns, params),
    makePageBreak(),
    makeDocxSubHeader($i18n.t('person.pdf.institutionsInfo.title')),
    makeDocxTable(institutionsData, institutionsColumns, params),
    makePageBreak(),
    makeDocxSubHeader($i18n.t('person.pdf.hospitalizationsInfo.title')),
    makeDocxTable(hospitalizationsData, hospitalizationsColumns, params),
  ]
}
