import { type ExportDataColumn, type ExportParams, makeDocxTable, makeFiltersDescriptions, makePDFHeading } from '~/pdfs/helpers'

export default (columns?: ExportDataColumn[], data?: any[], params?: ExportParams) => {
  return [
    ...(columns && data
      ? [
          ...makePDFHeading(params),
          makeFiltersDescriptions(params),
          makeDocxTable(data, columns, params),
        ]
      : makePDFHeading(params)),
  ]
}
